import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/pages/Home';
import About from '../src/pages/About';
import Contact from '../src/pages/Contact';
import Services from '../src/pages/Services';

import GenerativeAI from '../src/pages/services/Generative-AI';
import DataScience from '../src/pages/services/Data-Science';
import MachineLearning from '../src/pages/services/Machine-Learning';
import DevOpsEngineerring from '../src/pages/services/Dev-Ops-Engineerring';
import AiIntegratedApplicationDevlopment from '../src/pages/services/AI-Integrated-Application-Development';
import MobileAppDevelopment from '../src/pages/services/Mobile-Apps-Development';
import DocSenseProduct from './pages/services/doc-sense-product';
import ContentCraftProduct from './pages/services/content-craft-product';
import ProductsOverview from './pages/Product';




function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/Services" element={<Services/>} />
        <Route path="/product" element={<ProductsOverview/>} />
        {/* services */}
        <Route path="/Generative-AI" element={<GenerativeAI/>} />
        <Route path="/Data-Science" element={<DataScience/>} />
        <Route path="/Machine-Learning" element={<MachineLearning/>} />
        <Route path="/Dev-Ops-Engineerring" element={<DevOpsEngineerring/>} />
        <Route path="/AI-Integrated-Application-Devlopment" element={<AiIntegratedApplicationDevlopment/>} />
        <Route path="/Mobile-App-Development" element={<MobileAppDevelopment/>} />
         {/* Product */}
         <Route path="/doc-sense" element={<DocSenseProduct/>} />
         <Route path='/content-craft' element={<ContentCraftProduct/>} />'
      </Routes>
    </Router>
  );
}

export default App;
