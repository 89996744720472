import React, { useState, useEffect } from 'react';
import { ChevronDown, Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileServicesOpen, setMobileServicesOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-gradient-to-r from-blue-600 to-purple-600 py-5' : 'bg-transparent py-5'
      }`}>
      <div className="container mx-auto px-5">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <a href="/" className="group flex items-center space-x-2">
            <h1 className="text-2xl font-bold text-white">
              AI<span className="text-white/80 group-hover:text-white transition-colors">.Tech</span>
            </h1>
          </a>

          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/" className="text-white hover:text-white/80 transition-colors">
              Home
            </a>
            <a href="/about" className="text-white hover:text-white/80 transition-colors">
              About
            </a>

            {/* Services Dropdown */}
            <div
              className="relative"
              onMouseEnter={() => setServicesOpen(true)}
              onMouseLeave={() => setServicesOpen(false)}
            >
              <button
                className="flex items-center space-x-1 text-white hover:text-white/80 transition-colors py-2"
              >
                <span>Services</span>
                <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${servicesOpen ? 'rotate-180' : ''}`} />
              </button>

              {servicesOpen && (
                <div className="absolute top-full left-0 pt-4">
                  <div className="bg-white rounded-xl shadow-lg overflow-hidden w-56">
                    {[
                      { href: "/Generative-AI", label: "Generative AI" },
                      { href: "/data-science", label: "Data Science" },
                      { href: "/Dev-Ops-Engineerring", label: "DevOps" },
                      { href: "/AI-Integrated-Application-Devlopment", label: "AI-Powered Apps" }
                    ].map((item) => (
                      <a
                        key={item.href}
                        href={item.href}
                        className="block px-4 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600 transition-colors"
                      >
                        {item.label}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <a href="/product" className="text-white hover:text-white/80 transition-colors">
              Products
            </a>
            <a href="/contact" className="text-white hover:text-white/80 transition-colors">
              Contact
            </a>

            <button className="bg-white text-blue-600 px-6 py-2.5 rounded-lg hover:bg-white/90 transition-colors">
              Get Started
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white p-2"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
          </button>
        </div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 w-full bg-white shadow-lg rounded-b-xl overflow-hidden">
            <div className="flex flex-col">
              <a href="/" className="px-5 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600">
                Home
              </a>
              <a href="/about" className="px-5 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600">
                About
              </a>

              {/* Mobile Services Dropdown */}
              <div>
                <button
                  className="w-full px-5 py-3 flex items-center justify-between text-gray-700 hover:bg-blue-50 hover:text-blue-600"
                  onClick={() => setMobileServicesOpen(!mobileServicesOpen)}
                >
                  <span>Services</span>
                  <ChevronDown className={`h-4 w-4 transition-transform duration-200 ${mobileServicesOpen ? 'rotate-180' : ''}`} />
                </button>

                {mobileServicesOpen && (
                  <div className="bg-gray-50">
                    {[
                      { href: "/Generative-AI", label: "Generative AI" },
                      { href: "/data-science", label: "Data Science" },
                      { href: "/Dev-Ops-Engineerring", label: "DevOps" },
                      { href: "/AI-Integrated-Application-Devlopment", label: "AI-Powered Apps" }
                    ].map((item) => (
                      <a
                        key={item.href}
                        href={item.href}
                        className="block px-8 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600"
                      >
                        {item.label}
                      </a>
                    ))}
                  </div>
                )}
              </div>

              <a href="/product" className="px-5 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600">
                Products
              </a>
              <a href="/contact" className="px-5 py-3 text-gray-700 hover:bg-blue-50 hover:text-blue-600">
                Contact
              </a>

              <div className="p-5">
                <button className="w-full bg-blue-600 text-white px-6 py-2.5 rounded-lg hover:bg-blue-700 transition-colors">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;