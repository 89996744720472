import React from 'react';
import { ArrowRight, Brain, Database, Server, Code, ChevronRight } from 'lucide-react';

const HeroSection = () => {
  const services = [
    { icon: Brain, title: "Generative AI", desc: "State-of-the-art AI models for content generation" },
    { icon: Database, title: "Data Science", desc: "Transform data into actionable insights" },
    { icon: Server, title: "DevOps", desc: "Streamline your development pipeline" },
    { icon: Code, title: "Custom AI Apps", desc: "Tailored AI solutions for your business" }
  ];

  return (
    <div className="relative overflow-hidden">
      {/* Image Background */}
      <div className="absolute inset-0">
        <img
          src="/img/hero3.jpg"
          alt="AI Technology Background"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Overlays */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/90 via-blue-800/90 to-blue-900/90" />
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:60px_60px]" />
      <div className="absolute inset-0 bg-gradient-to-b from-black/5 to-black/30" />

      <div className="relative container mx-auto px-4 py-24">
        <div className="max-w-3xl mb-16">
          <h1 className="text-5xl font-bold text-white mb-6 leading-tight">
            Transform Your Business with
            <span className="text-blue-400"> AI Powered Solutions</span>
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Leverage cutting-edge artificial intelligence and machine learning to drive innovation,
            efficiency, and growth for your enterprise.
          </p>
          <div className="flex gap-4">
            <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg font-medium flex items-center">
              Get Started <ArrowRight className="ml-2 h-5 w-5" />
            </button>
            <button className="border border-white/30 hover:bg-white/10 text-white px-8 py-3 rounded-lg font-medium">
              View Services
            </button>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12">
          {services.map((service, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-lg rounded-xl p-6 hover:bg-white/15 transition-all group">
              <service.icon className="h-12 w-12 text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">{service.title}</h3>
              <p className="text-gray-300 mb-4">{service.desc}</p>
              <button className="text-blue-400 flex items-center group-hover:text-blue-300">
                Learn more <ChevronRight className="ml-1 h-4 w-4" />
              </button>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-20 border-t border-white/10 pt-12">
          <div>
            <div className="text-3xl font-bold text-white mb-2">500+</div>
            <div className="text-gray-400">Projects Delivered</div>
          </div>
          <div>
            <div className="text-3xl font-bold text-white mb-2">98%</div>
            <div className="text-gray-400">Client Satisfaction</div>
          </div>
          <div>
            <div className="text-3xl font-bold text-white mb-2">50+</div>
            <div className="text-gray-400">AI Models Deployed</div>
          </div>
          <div>
            <div className="text-3xl font-bold text-white mb-2">24/7</div>
            <div className="text-gray-400">Support Available</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;