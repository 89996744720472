import React from 'react';
import { ArrowRight, Mail, MapPin, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-[#1a237e]">
      <div className="container mx-auto px-4 pt-20">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 pb-12">
          {/* Company Info */}
          <div>
            <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
              <h2 className="text-4xl font-bold text-white mb-4">
                AI<span className="text-blue-400">.</span>Tech
              </h2>
              <p className="text-white text-lg mb-6">
                Leveraging cutting-edge AI technology to transform businesses and drive innovation.
              </p>
              <input 
                type="email" 
                placeholder="Enter your email"
                className="w-full bg-white/20 px-4 py-3 rounded-lg text-white placeholder-gray-300 mb-3"
              />
              <button className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg flex items-center justify-center text-lg">
                Get Updates <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>

          {/* Services */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <h3 className="text-2xl font-bold text-white mb-6">Our Services</h3>
            <ul className="space-y-4">
              {['Generative AI', 'Data Science', 'DevOps', 'Custom AI Apps'].map((service, i) => (
                <li key={i}>
                  <a href="#" className="text-white text-lg hover:text-blue-400 block">
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Quick Links */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <h3 className="text-2xl font-bold text-white mb-6">Quick Links</h3>
            <ul className="space-y-4">
              {['About Us', 'Case Studies', 'Contact', 'Careers'].map((link, i) => (
                <li key={i}>
                  <a href="#" className="text-white text-lg hover:text-blue-400 block">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact */}
          <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
            <h3 className="text-2xl font-bold text-white mb-6">Contact Us</h3>
            <ul className="space-y-4">
              {/* <li className="flex items-start">
                <MapPin className="h-6 w-6 text-blue-400 mt-1 mr-3" />
                <span className="text-white text-lg">123 Tech Street, San Francisco, CA</span>
              </li> */}
              {/* <li className="flex items-center">
                <Phone className="h-6 w-6 text-blue-400 mr-3" />
                <span className="text-white text-lg">+1 (555) 123-4567</span>
              </li> */}
              <li className="flex items-center">
                <Mail className="h-6 w-6 text-blue-400 mr-3" />
                <span className="text-white text-lg">info@alphaaiservice.com</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-white/20 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-white text-lg">© 2024 AI.Tech. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              {['Privacy', 'Terms', 'Support'].map((item, i) => (
                <a key={i} href="#" className="text-white text-lg hover:text-blue-400">
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;