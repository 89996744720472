import React from 'react';
import { Building2, Briefcase, ShoppingBag, Stethoscope, Building, Factory, Plane, Leaf } from 'lucide-react';

const IndustryServices = () => {
  const industries = [
    {
      icon: Building2,
      title: "Enterprise",
      services: ["Business Process Automation", "Enterprise AI Integration"]
    },
    {
      icon: Building,
      title: "Financial Services",
      services: ["Fraud Detection", "Risk Analysis", "Algorithmic Trading"]
    },
    {
      icon: Stethoscope,
      title: "Healthcare",
      services: ["Diagnostic AI", "Patient Data Analytics"]
    },
    {
      icon: ShoppingBag,
      title: "Retail",
      services: ["Customer Analytics", "Inventory Optimization"]
    },
    {
      icon: Factory,
      title: "Manufacturing",
      services: ["Predictive Maintenance", "Quality Control"]
    },
    {
      icon: Plane,
      title: "Transportation",
      services: ["Route Optimization", "Demand Forecasting"]
    },
    {
      icon: Briefcase,
      title: "Insurance",
      services: ["Claims Processing", "Risk Assessment"]
    },
    {
      icon: Leaf,
      title: "Energy",
      services: ["Grid Optimization", "Consumption Analytics"]
    }
  ];

  return (
    <div className="bg-gradient-to-b from-white to-gray-100 py-24">
      <div className="container mx-auto px-4">
        <div className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-4">
            <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
              AI Solutions by Industry
            </span>
          </h2>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Discover how our AI solutions can transform your industry with cutting-edge technology and innovation
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {industries.map((industry, index) => (
            <div 
              key={index} 
              className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 
                       border border-gray-100 group relative overflow-hidden"
            >
              {/* Decorative gradient background */}
              <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-purple-50 opacity-0 
                           group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative">
                <div className="flex items-start space-x-4">
                  <div className="bg-gradient-to-br from-blue-100 to-blue-50 p-4 rounded-xl
                               group-hover:scale-110 transition-transform duration-300 ease-out">
                    <industry.icon className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="flex-1">
                    <h3 className="font-semibold text-gray-900 text-lg mb-3">{industry.title}</h3>
                    <ul className="space-y-3">
                      {industry.services.map((service, idx) => (
                        <li key={idx} className="text-gray-600 flex items-center">
                          <div className="w-1.5 h-1.5 bg-gradient-to-r from-blue-600 to-purple-600 
                                      rounded-full mr-2 group-hover:scale-125 transition-transform" />
                          {service}
                        </li>
                      ))}
                    </ul>
                    <button className="mt-6 text-blue-600 font-medium inline-flex items-center
                                   group-hover:text-purple-600 transition-colors duration-300">
                      Learn more 
                      <span className="ml-1 transform group-hover:translate-x-1 transition-transform duration-300">
                        →
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-20 text-center">
          <button className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-10 py-4 
                         rounded-xl font-medium hover:shadow-lg transition-all duration-300
                         hover:scale-105 transform">
            Explore All Industry Solutions
          </button>
          <p className="text-gray-500 mt-4">
            Customized solutions for your specific industry needs
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndustryServices;