import React, { useState } from 'react';
import { MapPin, Phone, Mail, Clock, MessageSquare, Building2, ArrowRight } from 'lucide-react';
import Navbar from '../component/NavBar';

const Contact = () => {
  const [formType, setFormType] = useState('general');

  const contactInfo = [
    {
      icon: MapPin,
      title: "Our Location",
      details: ["India", "Australia"],
      color: "bg-blue-500"
    },
    // {
    //   icon: Phone,
    //   title: "Phone Numbers",
    //   details: ["+1 (555) 123-4567", "+1 (555) 765-4321"],
    //   color: "bg-purple-500"
    // },
    {
      icon: Mail,
      title: "Email Us",
      details: ["info@alphaaiservice.com"],
      color: "bg-indigo-500"
    },
    {
      icon: Clock,
      title: "Working Hours",
      details: ["Monday - Friday", "9:00 AM - 6:00 PM PST"],
      color: "bg-blue-600"
    }
  ];

  const inquiryTypes = [
    {
      id: 'general',
      title: 'General Inquiry',
      icon: MessageSquare
    },
    {
      id: 'sales',
      title: 'Sales',
      icon: Building2
    }
  ];

  return (
    <div>
        <Navbar/>
    
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-600 to-indigo-900 py-24">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px]" />
        <div className="container mx-auto px-4 relative">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-5xl font-bold text-white mb-6">
              Let's Connect
            </h1>
            <p className="text-xl text-blue-100">
              Get in touch with our team to discuss how we can help transform your business
            </p>
          </div>
        </div>
      </section>

      {/* Contact Information */}
      <section className="py-16 -mt-20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {contactInfo.map((item, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg p-6 transform hover:-translate-y-1 transition-all">
                <div className={`${item.color} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                  <item.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">{item.title}</h3>
                <ul className="space-y-2">
                  {item.details.map((detail, idx) => (
                    <li key={idx} className="text-gray-600">{detail}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-6xl mx-auto">
            <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
              <div className="grid lg:grid-cols-2">
                {/* Form */}
                <div className="p-8 lg:p-12">
                  <h2 className="text-3xl font-bold text-gray-900 mb-8">
                    Send us a Message
                  </h2>

                  {/* Inquiry Type Selector */}
                  <div className="flex space-x-4 mb-8">
                    {inquiryTypes.map((type) => (
                      <button
                        key={type.id}
                        onClick={() => setFormType(type.id)}
                        className={`flex items-center px-4 py-2 rounded-lg ${
                          formType === type.id
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                        } transition-colors`}
                      >
                        <type.icon className="h-5 w-5 mr-2" />
                        {type.title}
                      </button>
                    ))}
                  </div>

                  {/* Contact Form */}
                  <form className="space-y-6">
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Company
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Message
                      </label>
                      <textarea
                        rows={4}
                        className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      />
                    </div>

                    <button
                      type="submit"
                      className="w-full bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors flex items-center justify-center"
                    >
                      Send Message
                      <ArrowRight className="ml-2 h-5 w-5" />
                    </button>
                  </form>
                </div>

                {/* Map/Additional Info */}
                <div className="bg-gradient-to-br from-blue-600 to-indigo-900 p-8 lg:p-12 text-white">
                  <h3 className="text-2xl font-bold mb-6">Why Choose AI.Tech?</h3>
                  <ul className="space-y-6 mb-8">
                    {[
                      "Industry-leading AI solutions",
                      "Dedicated support team",
                      "Customized solutions for your business",
                      "Proven track record of success"
                    ].map((item, index) => (
                      <li key={index} className="flex items-start">
                        <div className="bg-blue-500/20 p-1 rounded mt-1 mr-3">
                          <ArrowRight className="h-4 w-4" />
                        </div>
                        {item}
                      </li>
                    ))}
                  </ul>

                  <div className="border-t border-white/20 pt-8">
                    <h4 className="font-semibold mb-4">Follow Us</h4>
                    <div className="flex space-x-4">
                      {['LinkedIn', 'Twitter', 'GitHub'].map((platform) => (
                        <a
                          key={platform}
                          href="#"
                          className="bg-white/10 px-4 py-2 rounded-lg hover:bg-white/20 transition-colors"
                        >
                          {platform}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            Frequently Asked Questions
          </h2>
          <div className="max-w-3xl mx-auto grid gap-6">
            {[
              {
                q: "How quickly can I expect a response?",
                a: "We typically respond to all inquiries within 24 business hours."
              },
              {
                q: "Do you offer custom solutions?",
                a: "Yes, we specialize in creating customized AI solutions tailored to your specific needs."
              },
              {
                q: "What kind of support do you provide?",
                a: "We offer 24/7 technical support for all our enterprise clients."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-gray-50 rounded-xl p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">{faq.q}</h3>
                <p className="text-gray-600">{faq.a}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default Contact;